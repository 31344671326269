<template>
  <v-card width="100%" height="100%" class="d-flex">
    <v-layout class="fill-height">
      <div style="flex: none; width: 300px; height: 100%; overflow-y: hidden; position: relative">
        <v-overlay v-model="loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-layout column style="background-color: var(--v-accent-base); height: 100%; overflow-y: hidden" class="py-2">
          <div class="pr-1" style="background-color: var(--v-primary-base); height: 50px">
            <v-layout fill-height align-center>
              <h3 class="ml-1">Fields</h3>
              <v-spacer/>
              <v-menu offset-y left v-model="menu" min-width="295" style="z-index: 400">
                <template v-slot:activator="{ on }">
                  <v-tooltip left color="#000000">
                    <template v-slot:activator="{ on, attrs }" v-on="on">
                      <v-btn
                          @click="menu = true"
                          icon
                          small
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>mdi-swap-vertical</v-icon>
                      </v-btn>
                    </template>
                    <span>Sort</span>
                  </v-tooltip>
                </template>
                <v-card class="py-1 elevation-6" style="background-color: #282828">
                  <v-radio-group v-model="sort" hide-details class="mt-1">
                    <v-radio
                        color="secondary"
                        class="custom-checkbox"
                        v-for="(item, index) in sorts"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-menu>
            </v-layout>
          </div>
          <v-layout column style="background-color: var(--v-accent-base); height: 100px" class="pa-2 pt-7">
            <v-text-field v-model="search" solo-inverted hide-details dense label="Search" append-icon="mdi-magnify"/>
          </v-layout>
          <v-layout column style="overflow-y: auto; height: 100%">
            <v-list width="100%" color="accent" class="pt-0" v-if="fields.length">
              <v-list-item-group mandatory>
                <template v-for="(field, index) in fields">
                  <v-list-item class="px-1 my-1 mx-0" @click="selectField(field)"
                               style="background-color: #171717; border-radius: 8px">
                    <v-layout align-center class="py-2" :class="'farm-' + index">
                      <div style="width: 70px; height: 70px; flex: none; position: relative; border-radius: 8px">
                        <MapPreview :geojson="field.featureCollection" :id="field.layerId"/>
                      </div>
                      <v-layout style="flex: 1; height: 70px;" class="px-2 pl-4" column>
                        <v-layout>
                          <div class="text-truncate" style="font-size: 15px; color: #eaeaff">{{ field.name }}</div>
                          <v-spacer/>
                          <v-tooltip left color="#000000">
                            <template v-slot:activator="{ on, attrs }" v-on="on">
                              <v-btn
                                  ư

                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  @click.native.stop="zoomToField(field)">
                                <!--                              <img alt="download" :src="require('@/assets/icon/fly.svg')">-->
                                <v-icon color="button" small>mdi-crosshairs-gps</v-icon>

                              </v-btn>
                            </template>
                            <span>Fly to</span>
                          </v-tooltip>
                        </v-layout>
                        <v-spacer/>
                        <v-layout column>
                        <span style="color:#79899A; font-size: 11.5px"
                              class="pr-2">{{ field.group ? field.group : '&nbsp;' }}</span>
                          <v-spacer/>
                          <v-layout style="padding-top: 2px">
                            <div v-if="field.current_season" style="color:#79899A; font-size: 11.5px"
                                 class="pr-2 text-truncate"
                                 v-html="field.current_season.seasonYear + ' - ' + field.current_season.cropName"></div>
                            <v-layout v-else>
                            </v-layout>
                            <v-spacer/>
                            <span style="color:#79899A; font-size: 11.5px" class="pr-2"
                                  v-html="transformArea(field.area)"></span>
                          </v-layout>
                        </v-layout>
                      </v-layout>
                    </v-layout>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <InfiniteLoading
                direction="bottom"
                @infinite="loadMore"
                :identifier="infiniteIndex"
            >
              <div slot="no-more"></div>
              <div slot="no-results"></div>
              <v-progress-circular
                  width="3"
                  slot="spinner"
                  indeterminate
                  color="navbar"
              ></v-progress-circular>
            </InfiniteLoading>
          </v-layout>
        </v-layout>
      </div>
      <v-layout column fill-height>
        <div style="height: 215px; min-height: 100px; flex: none; border-bottom: 1px solid #3b4559; position: relative" class="my-2">
          <v-overlay v-model="tableLoading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <div style="height: 100%; line-height: 100%; width: 100%; text-align: center" v-if="!group.length">
            <v-layout class="fill-height" align-center justify-center>
              <span style="font-size: 3rem; color: #3b4559; font-weight: 500">NODATA FOR ANALYTIC</span>
            </v-layout>
          </div>
          <v-layout class="fill-height" v-else>
              <div style="flex: none; width: 150px; height: 100%; border-right: 1px solid #3b4559">
                <div style="flex: none; height: 50px; width: 100%; border-bottom: 1px solid #3b4559">
                </div>
                <v-layout class="fill-height" column>
                  <div style="flex: none; height: 40px; width: 100%; border-bottom: 1px solid #3b4559">
                  </div>
                  <div style="flex: none; height: 40px; line-height: 40px; text-align: center; width: 100%; border-bottom: 1px solid #3b4559">
                    Low
                  </div>
                  <div style="flex: none; height: 40px; line-height: 40px; text-align: center; width: 100%; border-bottom: 1px solid #3b4559">
                    Medium
                  </div>
                  <div style="flex: none; height: 40px; line-height: 40px; text-align: center; width: 100%; border-bottom: 1px solid #3b4559">
                    High
                  </div>
                </v-layout>
              </div>
              <v-layout class="fill-height overflow-x-auto">
                <div v-for="item in group" :key="item.month + 'month'" style="border-right: 1px solid #3b4559; flex: 1">
                  <div style="flex: none; height: 50px; width: 100%; border-bottom: 1px solid #3b4559; text-align: center; line-height: 50px">
                    {{item.month}}
                  </div>
                  <div style="flex: none; height: 40px; width: 100%; border-bottom: 1px solid #3b4559; text-align: center;">
                    <v-layout>
                      <v-layout style=" border-right: 1px solid #3b4559; min-width: 135px; width: 100%" v-for="(date, index) in item.dates" :key="index" align-center justify-center column>
                        <v-layout style="height: 40px; min-width: 135px" align-center justify-center column>
                        {{ date.date }}
                        </v-layout>
                        <v-layout  v-for="(qa, ind) in quality" :key="date.date +'qa' + ind" style="height: 40px; min-width: 135px; width: 100%; border-bottom: 1px solid #3b4559" align-center justify-center column>
                          <v-btn
                              width="113"
                              @click="addDataToMap(quality, date)"
                              v-if="quality.level === qa " small text
                                 style="border-bottom: 2px solid red; text-transform: none"
                                 v-bind:style="{'border-bottom': `2px solid ${ind === 0 ? 'yellow': ind === 1 ? 'orange' : 'red'}`}"
                                 v-for="(quality, indx) in date.data"
                                 :key="'id' + indx">
                            {{ "Area: " + transformArea(quality.area) }}
                          </v-btn>
                        </v-layout>
                      </v-layout>
                    </v-layout>
                  </div>
                </div>
              </v-layout>
          </v-layout>
        </div>
        <v-layout style="flex: 1">
          <Map
              ref="map"
              :is-first-load="false"
              :isDraw="false"
              :sync.sync="sync"
              :isLoading.sync="loading"
              @selectField=""
          />
        </v-layout>
      </v-layout>
    </v-layout>
  </v-card>
</template>
<script>

import MenuAddField from "@/components/home/dashboard/FieldControl/MenuAddField.vue";
import MapPreview from "@/components/MapPreview.vue";
import AreaTransform from "@/ultis/convertArea";
import {getDisease, getField} from "@/backend";
import bbox from "@turf/bbox";
import {debounce} from "lodash"
import InfiniteLoading from "vue-infinite-loading"
import Map from "@/components/Map.vue";
import utils from "@/ultis/genUUID";

export default {
  components: {Map, MapPreview, MenuAddField, InfiniteLoading},
  data() {
    return {
      menu: false,
      firstLoad: true,
      currentField: undefined,
      quality: ['Low', 'Medium', 'High'],
      group: [],
      sync: true,
      loading: false,
      tableLoading: false,
      fields: [],
      page: 1,
      search: '',
      state: undefined,
      infiniteIndex: 1,
      desc: true,
      sort: '-created_at',
      sorts: [{
        name: 'Newest',
        value: '-created_at'
      }, {
        name: 'Oldest',
        value: 'created_at'
      }, {
        name: 'Field Name: A - Z',
        value: 'name'
      }, {
        name: 'Field Name: Z - A',
        value: '-name'
      }, {
        name: 'Field area: low to high',
        value: 'area'
      }, {
        name: 'Field area: high to low',
        value: '-area'
      }],
    }
  },
  watch: {
    sort() {
      this.searchField()
    },
    search() {
      this.mapLoading = true
      this.$nextTick(() => {
        this.searchField()
      })
      this.searchField()
    },
  },
  methods: {
    transformArea(area) {
      return AreaTransform.transformUnit(area)
    },
    searchField: debounce(function (e) {
      this.fields = []
      this.page = 1
      this.infiniteIndex = this.infiniteIndex + 1
    }, 300),
    async loadMore($state) {
      try {
        this.loading = true
        if ($state) this.state = $state
        let res = await getField({per_page: 10, page: this.page, search: this.search, sort: this.sort})
        if (res.data.data.length) {
          this.page = this.page + 1
          res.data.data.forEach(field => {
            field.layerId = 'aoi_' + field.id
            field.featureCollection = {
              "type": "FeatureCollection",
              "name": "field",
              "features": [
                {
                  "type": "Feature",
                  "properties": {},
                  "geometry": field.geometry
                }
              ]
            }
          })
          this.fields = this.fields.concat(res.data.data)
          this.state.loaded()
          // this.state.complete()
        } else {
          this.state.complete()
        }
      } catch (e) {
        this.state.loaded()
        this.state.complete()
      } finally {
        if (this.firstLoad) {
          this.firstLoad = false
          await this.selectField(this.fields[0])
        }
        this.loading = false
      }
    },
    zoomToField(field) {
      this.$refs.map.submitZoom(bbox(field.featureCollection))
    },
    async selectField(field) {
      try {
        this.group = []
        this.zoomToField(field)
        this.tableLoading = true
        this.currentField = field
        this.$refs.map.removeAllLayer()
        const res = await getDisease(field.id)
        this.group= res.data
      } catch (e) {
        console.log(e)
      } finally {
        this.tableLoading = false
      }
    },
    addDataToMap(quality, date) {
      this.$refs.map.removeAllLayer()
      this.$refs.map.addImageToMap(date.prev_tile_url,  utils.getUUID(), 'visible', bbox(this.currentField.featureCollection), true,  false)
      this.$refs.map.addImageToMap(date.tile_url,  utils.getUUID(), 'visible', bbox(this.currentField.featureCollection), false,  true)
      this.$refs.map.addPolygonToMap(
          quality.geometry,
          '#303F9F',
          utils.getUUID(),
          {},
          'fill',
          {
            'fill-color': '#ffffff',
            'fill-opacity': 0.4,
            'fill-outline-color': 'blue'
          }
      )
      this.$refs.map.addPolygonToMap(
          quality.geometry,
          '#303F9F',
          utils.getUUID(),
          {},
          'line',
      )
    }
  }
}
</script>

<style scoped>

</style>
